import CTACard from '../CTACard/CTACard';
import { SpaceWow } from '../SpaceWow';
import QuoteScorePicture from '@/public/img/infinity-lite/ProductCard_QuoteScore.webp';

export interface CTACard_QuoteScoreProps {
	testId?: string;
	image?: boolean;
}

const CTACard_QuoteScore = (props: CTACard_QuoteScoreProps) => {
	const { testId = 'CTACard_QuoteScore', image = false } = props;

	return (
		<CTACard testId={testId} type="link" color="lime" href="/quotescore/">
			{image && (
				<CTACard.Image
					src={QuoteScorePicture}
					alt="Reviewing a Quote Score report"
				/>
			)}
			<div className="px-6 py-8 lg:px-8 lg:pb-10 lg:pt-8">
				<CTACard.Badge color="lime">QuoteScore</CTACard.Badge>
				<SpaceWow spacer_01 lg__spacer_02 />
				<CTACard.Title>Get a quote reviewed</CTACard.Title>
				<SpaceWow spacer_03 lg__spacer_04 />
				<CTACard.Content>
					Have an HVAC quote already? Want to know if it&apos;s fair? We can
					help you understand the good, the bad, and the unclear.
				</CTACard.Content>
				<SpaceWow spacer_04 lg__spacer_06 />
				<CTACard.Action color="lime">Learn more</CTACard.Action>
			</div>
		</CTACard>
	);
};

export default CTACard_QuoteScore;
