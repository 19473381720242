'use client';

import { GoogleAnalyticsData } from '@/types/globals';
import type { Variant } from './button.d';
import { useEffect, useRef } from 'react';
import { getButtonClassname } from './helpers/getButtonClassname';
export interface ButtonProps {
	/** Allows us to change the text of the button. */
	children: React.ReactNode;

	/** Allows us to add extra styling properties onto the button. */
	className?: string;

	/** Triggers an event when the button is clicked. */
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

	/** Gives us different stylings, hover, and focus states of the button. */
	variant?: Variant;

	/** Allows us to set an enabled (clickable) or disabled (unclickable) state for the button. */
	disabled?: boolean;

	/** For internal testing and analytics */
	data?: GoogleAnalyticsData;

	ariaLabel?: string;
	setFocus?: boolean;
}

/** This is our base button component that we use for HVAC.com */
const Button = (props: ButtonProps) => {
	const {
		children,
		className = '',
		onClick,
		variant = 'solid',
		disabled = false,
		data = { testId: 'Button' },
		ariaLabel,
		setFocus = false,
	} = props;

	// Next time we upgrade react, we wont need this or the useEffect anymore.
	// We will be able to set autoFocus directly on the button like you would regular html
	const buttonRef = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		if (setFocus) {
			buttonRef.current?.setAttribute('autofocus', 'true');
		}
	}, [setFocus]);

	const classes = getButtonClassname(variant, disabled, className);

	return (
		<button
			ref={buttonRef}
			onClick={onClick}
			className={classes}
			disabled={disabled}
			data-testid={data.testId}
			data-action={data.action}
			data-location={data.location}
			data-text={data.text}
			data-postion={data.position}
			data-type={data.type}
			data-textgrouping={data.textGrouping}
			aria-label={ariaLabel}
		>
			{children}
		</button>
	);
};

export default Button;
