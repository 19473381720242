import CTACard from '../CTACard/CTACard';
import { SpaceWow } from '../SpaceWow';
import ChatPicture from '@/public/img/infinity-lite/ProductCard_Chat.webp';

export interface CTACard_ChatProps {
	testId?: string;
	onClick: () => void;
	image?: boolean;
	className?: string;
}

const CTACard_Chat = (props: CTACard_ChatProps) => {
	const { testId = 'CTACard_Chat', image = false, onClick, className } = props;

	return (
		<CTACard
			testId={testId}
			type="button"
			color="sky"
			onClick={onClick}
			className={className}
		>
			{image && (
				<CTACard.Image src={ChatPicture} alt="Chat with an HVAC technician" />
			)}
			<div className="px-6 py-8 lg:px-8 lg:pb-10 lg:pt-8">
				<CTACard.Badge color="sky">Live Chat</CTACard.Badge>
				<SpaceWow spacer_01 lg__spacer_02 />
				<CTACard.Title>Chat with an Advisor</CTACard.Title>
				<SpaceWow spacer_03 lg__spacer_04 />
				<CTACard.Content>
					Have an HVAC question? Whether it&apos;s about repairs,
					troubleshooting, or choosing a new system, we&apos;re here to help!
				</CTACard.Content>
				<SpaceWow spacer_04 lg__spacer_06 />
				<CTACard.Action color="sky">Get help now</CTACard.Action>
			</div>
		</CTACard>
	);
};

export default CTACard_Chat;
