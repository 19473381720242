'use client';

// Modules
import React from 'react';
// Components
import { CTACard_Replace } from '../CTACard_Replace';
import { CTACard_Repair } from '../CTACard_Repair';
import { CTACard_QuoteScore } from '../CTACard_QuoteScore';
import { CTACard_Chat } from '../CTACard_Chat';
// Helpers
import openChat from '@/helpers/hubspot/openChat';

export interface DualCTACardsProps {
	testId?: string;
	showQuoteScore?: boolean;
	showReplace?: boolean;
	showRepair?: boolean;
	showChat?: boolean;
	image?: boolean;
}

const DualCTACards = (props: DualCTACardsProps) => {
	const {
		testId = 'DualCTACards',
		showQuoteScore = false,
		showReplace = false,
		showRepair = false,
		showChat = false,
		image = false,
	} = props;

	return (
		<div
			data-testid={testId}
			className="mx-auto mb-10 flex max-w-5xl flex-col justify-between gap-7 lg:mb-20 lg:flex-row"
		>
			{showReplace && <CTACard_Replace image={image} />}
			{showRepair && <CTACard_Repair image={image} />}
			{showQuoteScore && <CTACard_QuoteScore image={image} />}
			{showChat && <CTACard_Chat onClick={openChat} image={image} />}
		</div>
	);
};

export default DualCTACards;
